import { CaretDownFill, CaretUpFill, FormCheckmark } from "grommet-icons";
import React from "react";

export const theme = {
  global: {
    colors: {
      brand: "#0056b4",
      grey: "#0030571F",
      grey2: "#9A9A9A",
      red: "#CE614A",
      focus: "#0056b4", // added focus color
      selected: "#0030571F",
    },
    focus: {
      border: {
        color: "transparent",
      },
    },
  },
  button: {
    color: "white",
    border: {
      radius: 0,
    },
  },
  accordion: {
    border: {
      color: "transparent",
    },
  },
  checkBox: {
    size: "18px",
    pad: {
      //right: "64px",
    },
    border: {
      color: "#9eb0bf",
      width: "2px",
    },
    hover: {
      border: {
        color: "#0056b4",
      },
    },
    toggle: {
      background: "#0056b4",
      // borderColor: "#0056b4",
    },
    icon: {
      size: "18px",
      extend: "stroke: white;",
    },
    icons: {
      checked: FormCheckmark,
    },
    check: {
      extend: ({ theme, checked }) => `
            ${checked && `background-color: #0056b4;`}
            `,
    },
  },
  textInput: {
    extend: {
      paddingTop: "21px",
      paddingBottom: "21px",
      paddingLeft: "17px",
      paddingRight: "17px",
      fontSize: "16px",
      fontWeight: "normal",
      letterSpacing: "0.5px",
    },
  },
  select: {
    icons: {
      down: <CaretDownFill color="brand" />,
      up: <CaretUpFill color="brand" />,
    },
    control: {
      extend: (props) => {
        // console.log(props.id);
        return `
              border: ${
                props.id === "error"
                  ? "2px solid #CE614A"
                  : "1px solid #0030571f"
              }; 
              border-radius: 0`;
      },
      open: {
        border: "2px solid #0056b4",
      },
    },
    // searchInput: SearchInput,
    container: {
      text: {
        size: "small",
      },
      extend: "max-height: 250px;",
    },
  },
  radioButton: {
    border: {
      width: "2px",
      color: "#9eb0bf",
    },
    hover: {
      border: {
        color: "brand",
      },
    },
    check: {
      extend: {
        outline: "none",
      },
    },
  },
  formField: {
    border: {
      color: "transparent",
      side: "all",
    },
    error: {
      color: "#CE614A",
      size: "12px",
      border: {
        color: "transparent",
      },
    },
    help: {
      size: "12px",
      color: "#669ad2",
    },
    info: {
      size: "12px",
      color: "#669ad2",
    },
    round: false,
  },
};
