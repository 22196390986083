import { Grommet } from "grommet";
import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Basics from "../pages/basics";
import References from "../pages/references";
import Review from "../pages/review";
import Sponsors from "../pages/sponsors";
import Success from "../pages/success";
import { theme } from "../theme";

import { useLocation } from "@reach/router";
import * as queryString from "query-string";

const customTheme = deepMerge(grommet, theme);

function FullForm() {
  const stage = useSelector((state) => state.global.stage);
  const dispatch = useDispatch();
  const [email, setEmail] = useState();

  const location = useLocation();

  useEffect(() => {
    const { email } = queryString.parse(location.search);
    setEmail(email);
  }, []);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
      window.location = `#stage${stage}`;
    }
  }, [stage]);

  return (
    <Grommet theme={customTheme} full={stage === 4}>
      {stage === 0 && <Basics />}
      {stage === 1 && <References />}
      {stage === 2 && <Sponsors />}
      {stage === 3 && <Review />}
      {stage === 4 && <Success />}
    </Grommet>
  );
}

export default FullForm;
