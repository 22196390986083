import { Heading, Box } from "grommet";
import React from "react";
import "../assets/styles/styles.scss";
import FormBlock from "../components/FormBlock";
import RadioInput from "../components/RadioInput";
import TextInput from "../components/TextInput";
import FileInput from "../components/FileInput";

import HorizontalRule from "../components/HorizontalRule";

function ReferencesSelect({ control, setError, setValue }) {
  return (
    <Box animation="fadeIn">
      <Heading fill level={2}>
        Sponsor 1
      </Heading>

      <FormBlock title={"What is your sponsor's name and contact details?"}>
        <TextInput control={control} name="sponsor_1_full_name" label={"Full Name"} />
        <TextInput
          control={control}
          name="sponsor_1_email"
          label={"Email Address"}
          validation={{pattern: /^\S+@\S+$/i}}
        />
      </FormBlock>

      <FormBlock title={"How long have you known each other?"}>
        <RadioInput
          name={"sponsor_1_how_long"}
          setValue={setValue}
          control={control}
          options={[
            "Under a year",
            "1-5 years",
            "6-10 years",
            "11-20 years",
            "21-30 years",
            "Over 30 years",
          ]}
          direction="column"
        />
      </FormBlock>

      <FormBlock title={"Please upload your sponsor’s letter of support:"}>
        <FileInput
          setError={setError}
          setValue={setValue}
          control={control}
          rootName="letter_support_1"
          label={"Letter of Support"}
          info={"PDF or DOC files supported. Max 10 Mb"}
        />
      </FormBlock>

      <HorizontalRule />

      <Heading fill level={2}>
        Sponsor 2
      </Heading>

      <FormBlock title={"What is your sponsor's name and contact details?"}>
        <TextInput control={control} name="sponsor_2_full_name" label={"Full Name"} />
        <TextInput
          control={control}
          name="sponsor_2_email"
          label={"Email Address"}
          validation={{pattern: /^\S+@\S+$/i}}
        />
      </FormBlock>

      <FormBlock title={"How long have you known each other?"}>
        <RadioInput
          name={"sponsor_2_how_long"}
          setValue={setValue}
          control={control}
          options={[
            "Under a year",
            "1-5 years",
            "6-10 years",
            "11-20 years",
            "21-30 years",
            "Over 30 years",
          ]}
          direction="column"
        />
      </FormBlock>

      <FormBlock title={"Please upload your sponsor’s letter of support:"}>
        <FileInput
          setError={setError}
          setValue={setValue}
          control={control}
          rootName="letter_support_2"
          label={"Letter of Support"}
          info={"PDF or DOC files supported. Max 10 Mb"}
        />
      </FormBlock>
    </Box>
  );
}

export default ReferencesSelect;
