import { Box, Grommet, Heading, ResponsiveContext } from "grommet";
import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";
import React, { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import ForwardIcon from "../assets/images/ic_arrow_forward.inline.svg";
import tiger from "../assets/images/tiger@2x.png";
import "../assets/styles/styles.scss";
import Button from "../components/Button";
import Footer from "../components/Footer";
import FormBlock from "../components/FormBlock";
import HorizontalRule from "../components/HorizontalRule";
import PageTitle from "../components/PageTitle";
import SponsorSelect from "../formBlocks/sponsorSelect";
import PageHeader from "../components/PageHeader";
import BackButton from "../components/BackButton";

import { useSelector, useDispatch } from "react-redux";
import { setStage, goBack } from "../state/globalSlice";
import { saveForm } from "../state/formSlice";

function Layout() {
  const dispatch = useDispatch();

  const size = useContext(ResponsiveContext);
  const isMobile = size === "small" || size === "xsmall" ? true : false;


  
  const {
    handleSubmit,
    control,
    formState,
    setError,
    getValues,
    setValue,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    dispatch(setStage(3));
  };

  // useEffect(() => {
  //   console.log(getValues());
  //   console.log("formState", formState.errors);
  // }, [formState]);

  const formSubmit = () => {
    const formValues = getValues();
    dispatch(saveForm(formValues));
    handleSubmit(onSubmit)();
  };

  return (
    <Box fill="horizontal">
      <PageHeader activeSection={3} />
      <Box
        animation="fadeIn"
        width={{ max: "952px" }}
        fill="horizontal"
        alignSelf="center"
        align="center"
        pad={{ horizontal: isMobile ? "24px" : "36px", top: isMobile ? "104px" : "250px" }}>
        <PageTitle
          fill
          isMobile={isMobile}
          title={`Proposer Details`}
          image={tiger}
          body={
            "Carriage House is a private Club with Membership offered exclusively by invitation. Please provide the below information in relation to your proposer."
          }
        />
        <HorizontalRule />
        <Box fill>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SponsorSelect
              control={control}
              setValue={setValue}
              setError={setError}
            />
            <FormBlock margin={{ top: "35px" }} backButton>
            <Box direction="row" gap="medium">
                {isMobile && <BackButton onClick={() => dispatch(goBack())} />}
                <Button
                  onClick={formSubmit}
                  color={"red"}
                  text={"Next Step"}
                  icon={<ForwardIcon />}
                />
              </Box>
            </FormBlock>
          </form>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
