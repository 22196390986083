import { Heading, Box } from "grommet";
import React from "react";
import "../assets/styles/styles.scss";
import FormBlock from "../components/FormBlock";
import RadioInput from "../components/RadioInput";
import TextInput from "../components/TextInput";

function ReferencesSelect({ control, setValue }) {
  return (
    <Box animation="fadeIn">
      <FormBlock title={"What is your proposers’s name and contact details?"}>
        <TextInput control={control} name="proposer_name" label={"Full Name"} />
        <TextInput
          control={control}
          name="proposer_email"
          label={"Email Address"}
          validation={{pattern: /^\S+@\S+$/i}}
        />
      </FormBlock>

      <FormBlock title={"How long have you known each other?"}>
        <RadioInput
          name={"proposer_how_long"}
          setValue={setValue}
          control={control}
          options={[
            "Under a year",
            "1-5 years",
            "6-10 years",
            "11-20 years",
            "21-30 years",
            "Over 30 years",
          ]}
          direction="column"
        />
      </FormBlock>
    </Box>
  );
}

export default ReferencesSelect;
