import { Heading } from "grommet";
import React, { useState } from "react";
import "../assets/styles/styles.scss";
import DateOfBirth from "../components/DateOfBirth";
import FormBlock from "../components/FormBlock";
import TextInput from "../components/TextInput";
import RadioInputWithInfo from "../components/RadioInputWithInfo";
import HorizontalRule from "../components/HorizontalRule";
import ProposerSection from "../formBlocks/proposerSection";
import SponsorSection from "../formBlocks/sponsorSection";
import { useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

function ReferencesSelect({ control, setValue, setError }) {
  // const pickSection = (e, value) => {
  //   setProposer(value);
  //   setValue("sponsorship_type", value, { shouldTouch: true });
  // };
  const defaultValue = useSelector((state) => state.form["sponsorship_type"]);

  // const proposer = useWatch({
  //   control,
  //   name: "sponsorship_type",
  //   defaultValue,
  // });

  return (
    <>
      <Heading fill level={2}>
        Proposer Details
      </Heading>

      {/* <FormBlock
        title={"Please confirm whether you have been proposed or sponsored:"}>
        <RadioInputWithInfo
          name={"sponsorship_type"}
          setValue={setValue}
          control={control}
          options={[
            { label: "Proposed", info: "I am proposed by a Member of the Admissions Committee." },
            { label: "Sponsored", info: "I am sponsored by two existing Members of the Club." },
          ]}
          direction="column"
        />
      </FormBlock>
      <HorizontalRule /> */}
      <ProposerSection setValue={setValue} control={control} />
    </>
  );
}

export default ReferencesSelect;
