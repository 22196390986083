import { Box, Grommet, Image, ResponsiveContext } from "grommet";
import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import cluboutline from "../assets/images/club_outline@2x.png";
import ForwardIcon from "../assets/images/ic_arrow_forward.inline.svg";
import DownloadIcon from "../assets/images/ic_download.inline.svg";
import croc from "../assets/images/croc@2x.png";
import "../assets/styles/styles.scss";
import Button from "../components/Button";
import FormBlock from "../components/FormBlock";
import Footer from "../components/Footer";
import HorizontalRule from "../components/HorizontalRule";
import PageTitle from "../components/PageTitle";
import BusinessInformation from "../formBlocks/businessInformation";
import ChildrenInformation from "../formBlocks/childrenInformation";
import PersonalInformation from "../formBlocks/personalInformation";
import SpouseInformation from "../formBlocks/spouseInformation";
import PageHeader from "../components/PageHeader";

import { useSelector, useDispatch } from "react-redux";
import { setStage } from "../state/globalSlice";

function Layout(props) {

  const size = useContext(ResponsiveContext);
  const isMobile = size === "small" || size === "xsmall" ? true : false;


  return (
      <Box fill>
        <PageHeader activeSection={5} />

        <Box
          width={{ max: "952px" }}
          fill="horizontal"
          flex
          alignSelf="center"
          align="center"
          pad={{ horizontal: isMobile ? "24px" : "36px", top: isMobile ? "104px" : "250px" }}>
          <PageTitle
            title="Application Complete"
            image={croc}
            body={
              "Thank you for submitting your application. You will soon receive an email with the concluding steps in the Membership application journey."
            }
          />
          <Box flex="grow" fill="horizontal" align="center">
            <Image fill fit="contain" src={cluboutline} />
          </Box>
          <Footer />
        </Box>
      </Box>
  );
}

export default Layout;
